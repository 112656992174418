import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';

const App = () => {
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [error, setError] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [pendingTasksCount, setPendingTasksCount] = useState(0);

    // Initialize PocketBase
    const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    // Fetch todos from PocketBase on mount
    const getRandomColor = () => {
        const minBrightness = 0.5; // Adjust this value as needed (0.0 to 1.0)
        let color;
        do {
            color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        } while (calculateBrightness(color) < minBrightness);
        return color;
    };
    const calculateBrightness = (hexColor) => {
        // Calculate brightness based on hex color (simple average of RGB channels)
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        return (r + g + b) / (255 * 3);
    };
    useEffect(() => {
        const fetchIp = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIpAddress(response.data.ip);
                console.log('IP address:', response.data.ip);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };
        fetchIp();
    }, []);
    useEffect(() => {
        // Fetch IP address
        fetch('https://api64.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIpAddress(data.ip))
            .catch(error => console.error('Error fetching IP address:', error));

        // Fetch initial todos
        fetchTodos();

        // Set up PocketBase subscription and keep reference to unsubscribe function
        const subscription = pb.collection('stagDo').subscribe('*', (e) => {
            //console.log('Subscription event:', e);
            if (e.action === 'create' || e.action === 'update' || e.action === 'delete') {
                fetchTodos();
            }
        });

        // Return cleanup function to unsubscribe when component unmounts
        return () => {
            pb.collection('stagDo').unsubscribe();
        };
    }, []); // Empty dependency array ensures it runs only on mount
    const fetchTodos = async () => {
        try {
            const result = await pb.collection('stagDo').getFullList(
                {
                    filter: `status="active"`,
                },
            );
            const pending = await pb.collection('stagDo').getFullList({
                filter: 'status="pending"',

            });
            setTodos(result);
            setPendingTasksCount(pending.length);
            console.log('Todos fetched:', pendingTasksCount);
        } catch (error) {
            console.error('Error fetching todos:', error);
        }
    };
    const handleAddTodo = async () => {
        if (newTodo.trim().length < 20 || newTodo.trim().length > 180) {
            setError('Todo text must be between 20 and 180 characters.');
            return;
        }

        setError('');
        setIsAdding(true);

        try {
            //console.log('Fetching todos for IP Address:', ipAddress);

            // Fetch todos for the current IP address
            const result = await pb.collection('stagDo').getFullList({
                filter: `ip="${ipAddress}"`,
            });

            //console.log('Todos for this IP address:', result);

            // Check if IP address has already added two todos
            if (result.length >= 2) {
                setError('You can only add two todos.');
                setIsAdding(false); // Reset isAdding state
                return;
            }

            //console.log('Attempting to create a new todo...');
            const record = await pb.collection('stagDo').create({
                text: newTodo,
                completed: false,
                status: 'pending',
                ip: ipAddress,
            });

            console.log('New todo created:', record);

            setTodos([record, ...todos]); // Add new todo at the beginning
            setNewTodo('');
        } catch (error) {
            //console.error('Error adding todo:', error);
            setError('Failed to add todo. Please try again.');
        } finally {
            setIsAdding(false); // Reset isAdding in finally block
            //console.log('Adding process finished');
        }
    };

    return (
        <div className="container mt-5 mb-3">
            <div className="d-flex justify-content-center">
                <img src={process.env.PUBLIC_URL + '/stag.png'}
                     alt="React logo"
                     style={{height: 100}}
                />
            </div>
            <div className="alert alert-warning" role="alert">
                {/*<h4 className="alert-heading">Welcome to Stiky's Barchelors Bash!</h4>*/}
                <p>
                    🎉 Welcome to Stiky’s wild stag do list! 🥳 Your mission?
                    Dream up tasks for the groom (Stiky) to tackle. Keep it SFW
                    (because the missus might peek!). You’ve got room for two
                    tasks—go crazy! Just remember, tasks become available
                    when we say so, and completing them might (or might not)
                    come with a surprise image. 📸 Ready? Let the madness begin! 😄
                    <br></br>
                    <b>From the BestMan & Groomsmen!</b>
                </p>
            </div>
            <div className="row mb-3">
                <div className="col text-center">
                    <p
                        className=" my-2 mx-3 pending-counter"
                    >
                        Pending: {pendingTasksCount}
                    </p>
                </div>
            </div>
            <div className="card shadow-sm">
                <div className="card-header bg-primaryBenn text-white justify-content-center">
                    <h5
                        className="card-title my-2 mx-3"
                        style={{textAlign: 'center'}}
                    >
                        Stiky's Barchelors Bash
                    </h5>
                </div>

                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col">
                            <div className="input-group">
                                <textarea
                                    className="form-control"
                                    placeholder="Add new task..."
                                    value={newTodo}
                                    onChange={(e) => setNewTodo(e.target.value)}
                                />
                                <button
                                    className="btn bg-primaryBenn ml-2"
                                    type="button"
                                    disabled={isAdding || newTodo.trim().length === 0}
                                    onClick={handleAddTodo}
                                    style={{color: 'white'}}
                                >
                                    {isAdding ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                            {' '}Adding...
                                        </>
                                    ) : (
                                        'Add'
                                    )}
                                </button>
                            </div>
                            {error && <div className="text-danger mt-2">{error}</div>}
                        </div>
                    </div>

                    <div className="row">

                        <div className="col">
                            {todos.map((todo, index) => (
                                <div key={todo.id} className={`card mb-3 mt-4 ${index % 2 === 0 ? 'bg-light' : ''}`}>
                                    <div
                                        className={`card-body align-items-center  ${todo.completed ? 'completed' : ''}`}>
                                        <div className="d-flex justify-content-center bennD">
                                            <span
                                                className="badge me-3 benn"
                                                style={{backgroundColor: getRandomColor()}}
                                            >{index + 1}</span>
                                        </div>

                                        <div style={{flex: 1}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="card-text"
                                                   style={{
                                                       wordWrap: 'break-word',
                                                       maxWidth: '100%',
                                                       overflowWrap: 'break-word'
                                                   }}>
                                                    {todo.completed ? <del>{todo.text}</del> : todo.text}
                                                </p>
                                            </div>
                                            <p className="card-text text-muted">
                                                Created on {new Date(todo.created).toLocaleDateString()}
                                            </p>
                                        </div>
                                        {/*<button
                                            className="btn btn-sm btn-outline-primary mr-2"
                                            onClick={() => handleToggleComplete(todo.id, todo.completed)}
                                        >
                                            {todo.completed ? 'Mark Incomplete' : 'Mark Complete'}
                                        </button>*/}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default App;
